import { ethers } from 'ethers'
import { getContract } from '../functions'
import settings from '../settings'

export function getTotalSupply(library) {
  const contract = getContract(library)
  return contract.totalSupply()
}

export function getCurrentMintCount(library, address) {
  const contract = getContract(library)

  return contract.getCurrentMintCount(address)
}

export async function saleMint(library, address, numberOfTokens) {
  const contract = getContract(library)
  const signer = library.getSigner(address)

  const tx = await contract.connect(signer).publicSaleMint(
    numberOfTokens,
    {
      value: ethers.utils.parseUnits(
        String(45000000 * numberOfTokens),
        'gwei',
      ).toHexString(),
    }
  )

  if (settings.env === 'development') {
    window.lastTx = tx
  }

  return tx
}

export function parseLogs(library, receipt) {
  const contract = getContract(library)

  const logs = receipt.logs.map(x => contract.interface.parseLog(x))

  return logs
}
