import { formatEther } from "@ethersproject/units"
import { useWeb3React } from "@web3-react/core"
import { useEffect, useState } from "react"
import { claimTokens, getTotalClaimable } from "./functions"
import { useModal } from 'react-context-modals'
import settings from "../settings"

export function ClaimTokens() {
    const { account, library } = useWeb3React()

    let [transaction, setTransaction] = useState()
    let [receipt, setReceipt] = useState()
    let [message, setMessage] = useState()

    const handleOnClick = async () => {
        try {
            setMessage(undefined)
            const tx = await claimTokens(library, account)
            setTransaction(tx)

            setMessage(['white', <><a href={'https://etherscan.io/tx/' + tx.hash} style={{textDecoration: 'underline'}} target={'_blank'}>Transaction is sent</a>, please wait.</>])

            const receipt = await tx.wait()
            setReceipt(receipt)

            setMessage(['white', <a href={'https://etherscan.io/tx/' + tx.hash}>Token claim is successful!</a>])
            

            if (settings.env === 'development') {
                window.lastReceipt = receipt
            }
        }
        catch (e) {
            // e.code === 4001 - User denied transaction signature.
            console.error(e)

            if (e.code === 4001) {
                if (message !== undefined) {
                    setMessage(undefined)
                }
            } else if (e.code === 'INSUFFICIENT_FUNDS') {
                setMessage(['red', 'insufficient funds for transaction cost']) //  (gas * price + value)
            } else {
                const errorDetailIndex = e.message.indexOf(' (error=')
                if (errorDetailIndex !== -1) {
                    setMessage(['red', e.message.substring(0, errorDetailIndex)])
                } else {
                    setMessage(['red', e.message])
                }
            }
        }
    }

    return (
        <div>
            <a className="token-add-to-metamask theme-btn mt-8 mb-2" onClick={() => {
                handleOnClick()
            }}>
                Claim Tokens
            </a>
            <div className="w-full flex justify-center text-center mt-4">
            {message && <span style={{
                color: message[0]
            }}>{message[1]}</span>}
            </div>
        </div>
    )
}

export function AddToMetamask() {
    const { account, library } = useWeb3React()

    return (
        <div>
            <a className="token-add-to-metamask theme-btn mt-32 mb-2" onClick={() => {
                library.provider.sendAsync({
                    method: 'metamask_watchAsset',
                    params: {
                        "type": "ERC20",
                        "options": {
                            "address": settings.tokenContractAddress,
                            "symbol": settings.tokenSymbol,
                            "decimals": settings.tokenDecimals,
                            "image": settings.tokenImage,
                        },
                    },
                    id: Math.round(Math.random() * 99999999),
                })
            }}>
                Add $MBM to MetaMask
            </a>
            <small class='text-small'>If you haven't claimed $MBM before, it will show 0 balance.</small>
        </div>
    )
}

export function ClaimYieldToken() {
    const { account, library } = useWeb3React()
    const [claimableAmount, setClaimableAmount] = useState(0)

    const { showModal } = useModal()

    const checkClaimable = async () => {
        if (account) {
            const claimable = await getTotalClaimable(library, account)
            setClaimableAmount(claimable)
        }
    }

    useEffect(() => {
        checkClaimable()
    }, [account])

    useEffect(() => {
        setInterval(() => {
            checkClaimable()
        }, 3000)
    }, [])

    if (account && claimableAmount) {
        return (
            <div className="flex flex-col">
                <a className="theme-btn" onClick={() => {
                    showModal(ClaimYieldToken)
                }}>
                    {formatEther(claimableAmount.sub(claimableAmount.mod(1e15)))} $MBM
                </a>
                <ClaimTokens />
                <AddToMetamask />
            </div>
        )
    }

    return (
        <></>
    )
}

export function YieldToken() {
    const { account, library } = useWeb3React()
    const [claimableAmount, setClaimableAmount] = useState(0)

    const { showModal } = useModal()

    const checkClaimable = async () => {
        if (account) {
            const claimable = await getTotalClaimable(library, account)
            setClaimableAmount(claimable)
        }
    }

    useEffect(() => {
        checkClaimable()
    }, [account])

    useEffect(() => {
        setInterval(() => {
            checkClaimable()
        }, 3000)
    }, [])

    if (account && claimableAmount) {
        return (
            <a className="theme-btn" onClick={() => {
                showModal(ClaimYieldToken)
            }}>
                {formatEther(claimableAmount.sub(claimableAmount.mod(1e15)))} $MBM
            </a>
        )
    }

    return (
        <></>
    )
}