import { ethers } from 'ethers'
import { getContract as getNFTContract } from '../functions'
import contractAbi from './contract.abi.json'
import settings from '../settings'

export function getContract(library) {
  const contract = new ethers.Contract(settings.tokenContractAddress, contractAbi, library)

  if (settings.env === 'development') {
    window.contract = contract
  }

  return contract
}

export function getTotalClaimable(library, address) {
  const contract = getContract(library)

  console.log(contract)

  return contract.getTotalClaimable(address)
}

export async function claimTokens(library, address) {
  const contract = getNFTContract(library)
  const signer = library.getSigner(address)

  const tx = await contract.connect(signer).functions.getReward()

  if (settings.env === 'development') {
    window.lastTx = tx
  }

  return tx
}
