import { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useModal } from 'react-context-modals'

import { Balance, ConnectWalletModal } from './connect-wallet-modal'

export const ConnectWalletButton = ({ handleMouseEnter, handleMouseLeave, mobile }) => {
  const { account, library, deactivate, connector } = useWeb3React()
  const { showModal } = useModal()
  const [ens, setENS] = useState()

  const handleWalletConnect = () => {
    showModal(ConnectWalletModal)
  }

  useEffect(() => {
    (async () => {
      if (account) {
        const name = await library.lookupAddress(account)
        setENS(name)
      }
    })()
  }, [account, library])

  const accountString = ens ? ens : (account ? account.substring(0, 6) + '...' + account.substring(38) : '')
  const balance = Balance()

  const Desktop = () => (<>
    {
      account ?
        <>
          <a href="#disconnect" className={'connect-disconnect-button'} rel="noreferrer"
             className={'theme-btn'}
             onClick={(e) => {
               e.preventDefault()

               deactivate()
               try {
                 connector.close()
               } catch (e) {
                 console.error(e)
               }
             }}
          >
            {accountString} <br/>
            <small>{balance}</small>
          </a>
        </>
        :
        <>
          <a href="#connect" className={'connect-disconnect-button'} rel="noreferrer"
             className={'theme-btn'}
             onClick={(e) => {
               e.preventDefault()

               handleWalletConnect()
             }}
          >
            Connect Wallet
          </a>
        </>
    }
  </>)

  const Mobile = () => (<>
    {
      account ?
        <>
          <style
            dangerouslySetInnerHTML={{ __html: '\n            .cls-1, .cls-2, .cls-3 {\n            font-size: 76px;\n            fill: #1c1c1c;\n            text-anchor: middle;\n            font-family: kang;\n          }\n\n            .cls-1 {\n            filter: url(#filter);\n          }\n\n            .cls-3 {\n            stroke: #dbba41;\n            stroke-linejoin: round;\n            stroke-width: 14px;\n          }\n .cls-3 {transition: .3s all;}    a:hover > .cls-3 { stroke-width: 20px; }   ' }}/>
          <a href="#disconnect" className={'connect-disconnect-button'} rel="noreferrer"
             className={'theme-btn'}
             onClick={(e) => {
               e.preventDefault()

               deactivate()
               try {
                 connector.close()
               } catch (e) {
                 console.error(e)
               }
             }}
          >
            {accountString} <br/>
            <small>{balance}</small>
          </a>
        </>
        :
        <>
          <a href="#connect" className={'connect-disconnect-button'} rel="noreferrer"
             className={'theme-btn'}
            onClick={(e) => {
              e.preventDefault()

              showModal(ConnectWalletModal)
            }}
          >
            Connect Wallet
          </a>
        </>
    }
  </>)

  return mobile ? <Mobile/> : <Desktop/>
}
