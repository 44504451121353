import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Web3ReactProvider } from '@web3-react/core'

import { getLibrary } from './functions'

import { ModalProvider } from 'react-context-modals'
import 'react-context-modals/dist/main.css'


import "./index.css";
import MainLayout from "./main/layout.jsx";
import Home from "./main/home/index.jsx";


ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <ModalProvider animated>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Home />} />
            {
              /* <Route path="teams" element={<Teams />}>
              <Route path=":teamId" element={<Team />} />
              <Route path="new" element={<NewTeamForm />} />
              <Route index element={<LeagueStandings />} />
            </Route> */
            }
          </Route>
        </Routes>
      </BrowserRouter>
      </ModalProvider>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
