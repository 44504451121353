import { useWeb3React } from '@web3-react/core'
import { useEffect, useState, useRef } from 'react'
import { handleChainChange } from '../functions'
import settings from '../settings'
import { getCurrentMintCount, saleMint, parseLogs, getTotalSupply } from './functions'


const CheckStep = ({ setStep, step, setSupply }) => {
  const { account, library, chainId } = useWeb3React()

  useEffect(() => {
    if (settings.enableSwitchingNetwork && chainId && chainId !== 1) {
      handleChainChange()
    }
  }, [chainId])

  useEffect(() => {
    async function checkSupply() {
      const result = await getTotalSupply(library)

      if (result >= 8888){
        setStep('soldout')
      } else {
        setSupply(result)
        setStep('mint')
      }
    }

    checkSupply()
  }, [account, library, setStep])

  return (
    <>
      <div className="flex flex-col justify-center text-center w-full" style={{
        minWidth: '50vw'
      }}>
        <h2 className={'text-5xl font-kang'}>Sale</h2>
        <div className={'font-sans text-2xl my-8'}>Hey Boomer!<br/>First we're checking whether sale is active<br/>Please
          wait.</div>
      </div>
    </>
  )
}

const SoldoutStep = () => {
  return (
    <>
      <div className="flex flex-col justify-center text-center w-full" style={{
        minWidth: '50vw'
      }}>
        <h2 className={'text-5xl font-kang'}>Soldout!</h2>
        <div className={'font-sans text-2xl my-8'}>8,888 Boomers already minted. But you can still buy on OpenSea. !poof</div>
        <a href="https://opensea.io/collection/metaboomers" target={'_blank'} className={'font-kang theme-btn text-4xl'}>Buy on OpenSea</a>
      </div>
    </>
  )
}

const MintStep = ({ supply, setStep, setMintState }) => {
  const { account, library } = useWeb3React()

  const [balance, setBalance] = useState(undefined)
  const [message, setMessage] = useState()
  const numberOfTokensRef = useRef(null)

  async function mint() {
    const acc = String(account)

    const numberOfTokens = Number(numberOfTokensRef.current.value)

    setMessage(undefined)

    try {
      const tx = await saleMint(library, acc, numberOfTokens)

      setMintState({
        tx
      })
      setStep('final')
    }
    catch (e) {
      // e.code === 4001 - User denied transaction signature.
      console.error(e)

      if (e.code === 4001) {
        if (message !== undefined) {
          setMessage(undefined)
        }
      } else if (e.code === 'INSUFFICIENT_FUNDS') {
        setMessage(['red', 'insufficient funds for transaction cost']) //  (gas * price + value)
      } else {
        const errorDetailIndex = e.message.indexOf(' (error=')
        if (errorDetailIndex !== -1) {
          setMessage(['red', e.message.substring(0, errorDetailIndex)])
        } else {
          setMessage(['red', e.message])
        }
      }
    }
  }

  useEffect(() => {
    async function getBalance() {
      const acc = String(account)

      const used = await getCurrentMintCount(library, acc)

      setBalance({
        used: used,
        remaining: Math.min(20 - used, 10),
      })
    }

    getBalance()
  }, [account, library])

  function Picker(props) {
    let maxNumberOfTokens = props.balance.remaining
    let supply;

    if (!props.balance) {
      return null
    }

    if (maxNumberOfTokens < 1) {
      return (
        <div className={'font-sans text-2xl my-8'}>You've already used all your tokens.</div>
      )
    }

    if (supply > maxNumberOfTokens) {
      maxNumberOfTokens = supply
    }

    return (
      <>
        Pick number of tokens to mint:
        <br />
        <br />
        Number of Tokens:
        <select ref={numberOfTokensRef}>
          {new Array(maxNumberOfTokens).fill(1).map((_, i) => (
            <option key={i} value={i + 1}>{i + 1}</option>
          ))}
        </select>
        <br />
        <br />
        {message && (
          <>
            <div style={{ color: message[0] }}>{message[1]}</div>
            <br />
            <br />
          </>
        )}
        <a className={'theme-btn btn-big mx-auto'} onClick={() => mint()}>
          Mint
        </a>
      </>
    )
  }

  return (
    <>
      <div className="flex flex-col justify-center text-center w-full" style={{
        minWidth: '50vw'
      }}>
        <h2 className={'text-5xl font-kang'}>Sale</h2>
        <div className={'font-sans text-2xl my-8'}>
          Hey Boomer!
          <br/>
          {balance && <Picker balance={balance} />}
        </div>
      </div>
    </>
  )
}

const FinalStep = ({ setStep, mintState }) => {
  const { account, library } = useWeb3React()

  const [ mintedTokens, setMintedTokens ] = useState()

  useEffect(() => {
    async function getLogs() {
      const { tx } = mintState

      const receipt = await tx.wait()

      if (settings.env === 'development') {
        window.lastReceipt = receipt
      }

      const receiptLogs = parseLogs(library, receipt)

      if (settings.env === 'development') {
        window.lastReceiptLogs = receiptLogs
      }

      const tokenIds = receiptLogs
        // .filter(log => log.event === 'Transfer')
        .map(receiptLog => receiptLog.args[2].toString())

      setMintedTokens(tokenIds)
    }

    getLogs()
  }, [account, library, mintState])

  const txHash = mintState.tx.hash
  const txUrl = `${settings.contractEtherscanUrl}/tx/${txHash}`

  const tokenUrls = mintedTokens && mintedTokens.map(
    tokenId => [
      tokenId,
      `${settings.contractOpenseaUrl}/assets/${settings.contractAddress}/${tokenId}/?force_update=true`,
      `${settings.contractRaribleUrl}/token/${settings.contractAddress}:${tokenId}`
    ]
  )

  return (
    <>
      <div className="flex flex-col justify-center text-center w-full" style={{
        minWidth: '50vw'
      }}>
        <h2 className={'text-5xl font-kang'}>Sale</h2>
        <div className={'font-sans text-2xl my-8'}>
          {tokenUrls && <>
            Thanks Boomer!
          </>}
          <br/>
          <br />
          <div>
            <a href={txUrl} target={'_blank'} rel="noreferrer">View transaction {txHash.substring(0, 8)}... on Etherscan</a>
          </div>
          <br />
          {(tokenUrls ?? []).map(([tokenId, openseaUrl, raribleUrl]) => (
            <div key={tokenId} className="flex justify-evenly items-center">
              <span>Successfully minted {tokenId}</span> <br/>
              <a href={openseaUrl} target={'_blank'} rel="noreferrer" className={'theme-btn'}>OpenSea</a>
              <a href={raribleUrl} target={'_blank'} rel="noreferrer" className={'theme-btn'}>Rarible</a>
            </div>
          ))}
          {!tokenUrls && <div>Waiting for transaction to be completed...</div>}
        </div>
      </div>
    </>
  )
}

export default function SaleWizard () {
  const [step, setStep] = useState('check')
  const [supply, setSupply] = useState('check')
  const [mintState, setMintState] = useState(undefined)

  const steps = [
    ['check', <CheckStep step={step} setStep={setStep} setSupply={setSupply}/>],
    ['soldout', <SoldoutStep/>],
    ['mint', <MintStep setStep={setStep} setMintState={setMintState} supply={supply}/>],
    ['final', <FinalStep setStep={setStep} mintState={mintState}/>],
  ]

  return steps.find(s => s[0] === step)[1]
}
