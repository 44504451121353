import { useState, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { ConnectWalletButton } from '../connect/connect-wallet-button'
import {isPresaleActive, isSaleActive} from '../functions'
import PresaleWizard from '../presale/index'
import SaleWizard from '../sale/index'

const SaleSwitch = () => {
  const { account, library } = useWeb3React()

  const [_isPresaleActive, setIsPresaleActive] = useState()
  const [_isSaleActive, setIsSaleActive] = useState()
  const [checkInterval, setCheckInterval] = useState()

  const checkSales = async () => {
    if (account) {
      let isPresale = await isPresaleActive(library)
      setIsPresaleActive(isPresale)

      let isSale = await isSaleActive(library)
      setIsSaleActive(isSale)

      if(isPresaleActive || isSaleActive) {
        clearInterval(checkInterval)
      }
    }
  }

  useEffect(() => {
    let interval = setInterval(() => {
      checkSales()
    }, 3000)

    setCheckInterval(interval)

    checkSales()
  }, [])

  if (_isPresaleActive) {
    return <PresaleWizard />
  }

  if (_isSaleActive) {
    return <SaleWizard />
  }
  
  return <>
    Sales haven't started yet
  </>
}

const SaleArea = () => {
  const { account } = useWeb3React()

  if (!account) {
    return <ConnectWalletButton/>
  }

  return (
    <SaleSwitch/>
  )
}

export default SaleArea
